<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";
export default {
  page: {
    title: "Nouveau rôle",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Nouveau rôle",
      suppliers: [],
      users: [],
      directions: [],
      divisions: [],
      departments: [],
      services: [],
      roleForm: {
        name: "",
        description: "",
      },
      userStructuresTypes: [],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      items: [
        {
          text: "Paramètres",
          href: "/",
        },
        {
          text: "Nouveau rôle",
          active: true,
        },
      ],
    };
  },

  validations: {
    roleForm: {
      name: { required },
    },
  },

  created() {
    
  },

  methods: {
    formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      var router = this.$router;
      this.$http
        .post("/system/roles/store", this.roleForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              router.push({ name: "settings.roles.index" });
              break;

            case 500:
                this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
            this.$toast.error(error.message);
        })
        .finally(() => {});
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <b-alert show variant="info">
                <i class="mdi mdi-alert-circle-outline mr-2"></i>Les champs
                obligatoires sont marqués avec (*)
              </b-alert>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="formrow-email-input">Désignation *</label>
                    <input
                      id="projectname"
                      v-model="roleForm.name"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.roleForm.name.$error,
                      }"
                      placeholder=""
                    />
                    <div
                      v-if="submitted && $v.roleForm.name.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.roleForm.name.required"
                        >La référence du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="formrow-email-input">Description</label>
                    <input
                      id="projectname"
                      v-model="roleForm.description"
                      type="text"
                      class="form-control"
                      
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-12">
                  <button :disabled="$v.roleForm.$invalid" class="btn btn-primary float-right">
                    <i class="fas fa-save"></i> Enregistrer
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
